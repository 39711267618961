import { FC } from "react";

import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import calculateSum from "web/utils/page/product/subscription/calculateSum";
import { getPointsBankName } from "web/utils/page/product/subscription/prepareBenefitsPricesOption";

import {
  SOURCE_FOUND_LABEL_SUMMARY,
  pointsPerMonth,
  zlotyPerMonth,
} from "web/constants/benefits";

import { PropsWithClasses } from "web/types/Common";
import { Nullable } from "web/types/Utils";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

interface IPriceData {
  value: string;
  price: string;
  label: string;
}

interface ISubscriptionFoundingSourceProps {
  employeePrice?: string;
  employerPrice?: string;
  pointsBankPrice?: string;
  pointsBankId: Nullable<string>;
  isCoFinanced: boolean;
  isResigned?: boolean;
  isEmployerPriceHidden: boolean;
  alternativeText: Nullable<string>;
}

const SubscriptionFoundingSource: FC<
  PropsWithClasses<ISubscriptionFoundingSourceProps>
> = ({
  classes = {},
  employeePrice = "0",
  employerPrice = "0",
  pointsBankPrice = "0",
  pointsBankId,
  isCoFinanced = false,
  isResigned = false,
  isEmployerPriceHidden = false,
  alternativeText,
}) => {
  const { data: dataBanks } = useGetBanksQuery();
  const { items: pointsBanks } = dataBanks || {};
  const pointsBankLabel = getPointsBankName(pointsBanks, pointsBankId);
  const finalCost = calculateSum([
    employeePrice,
    employerPrice,
    pointsBankPrice,
  ]);

  const pricesData = [
    {
      value: "employee",
      price: employeePrice,
      label: SOURCE_FOUND_LABEL_SUMMARY.employee,
    },
    {
      value: "employer",
      price: employerPrice,
      label: SOURCE_FOUND_LABEL_SUMMARY.employer,
    },
    {
      value: "points",
      price: pointsBankPrice,
      label: pointsBankLabel,
    },
  ];

  const currentPriceData = pricesData.filter(
    (ele) => +ele.price && ele.price !== "0.00"
  );
  const currency =
    currentPriceData[0]?.value === "points" ? pointsPerMonth : zlotyPerMonth;

  // TODO: Move to util, possibly reuse in prepareBenefitsPricesOption.ts
  const createLabelWithPrefix = (value: string, label: string) => {
    if (isEmployerPriceHidden && !isCoFinanced) return alternativeText;

    const coFinancedprefix =
      SOURCE_FOUND_LABEL_SUMMARY[
        value as keyof typeof SOURCE_FOUND_LABEL_SUMMARY
      ];

    if (value === "points") {
      return isCoFinanced
        ? `${__(coFinancedprefix)} ${label}`
        : `100% ${__(coFinancedprefix)} ${label}`;
    }

    if (isResigned && pointsBankLabel) {
      return `100% ${__(SOURCE_FOUND_LABEL_SUMMARY.points)} ${pointsBankLabel}`;
    }

    return isCoFinanced ? __(coFinancedprefix) : `100% ${__(coFinancedprefix)}`;
  };

  // TODO: Move to util, possibly reuse in prepareBenefitsPricesOption.ts
  const handleCoFinancedOption = (pricesData: IPriceData[]) => {
    const coFinanced = pricesData.filter(
      (priceData) => +priceData.price && priceData.price !== "0.00"
    );

    if (isCoFinanced) {
      const coFinancedFinalCost =
        coFinanced.length > 1
          ? coFinanced.reduce((sum, current) => sum + Number(current.price), 0)
          : coFinanced[0]?.price;

      return (
        <>
          <div className={`${classes.item} ${classes.item__ums}`} data-1="funding source">
            <span
              className={classes.sourceFound__title}
              data-t1="fundingSourceTitle"
            >
              {__("Współfinansowany")}
            </span>
            {coFinanced.map((financedEle) => {
              const isThatPointsFinanced = financedEle.value === "points";
              return (
                <div 
                  className={classes.item__sourceFound} 
                  data-1={isThatPointsFinanced ? "pointsValue" : "employeeCosts"}>
                {financedEle.price}
                {__(
                  isThatPointsFinanced
                    ? pointsPerMonth
                    : zlotyPerMonth
                )}{" "}
                - {createLabelWithPrefix(financedEle.value, financedEle.label)}
              </div>
              )
            })}
            {isEmployerPriceHidden && alternativeText ? (
              <div
                className={classes.item__sourceFound}
                data-t1="hiddenCostMessage"
              >
                {alternativeText}
              </div>
            ) : null}
          </div>
          <div className={`${classes.item} ${classes.item__ums}`} data-1="price value">
            <Price value={coFinancedFinalCost} currency={__(zlotyPerMonth)} />
          </div>
        </>
      );
    }

    return null;
  };

  const coFinancedJSX = handleCoFinancedOption(pricesData);
  const isItPointsOrZloty = currency === pointsPerMonth ? "pointsValue" : "employeeCosts";

  return (
    coFinancedJSX || (
      <>
        <div className={classes.item} data-t1={isItPointsOrZloty}>
          {createLabelWithPrefix(
            currentPriceData[0]?.value,
            currentPriceData[0]?.label
          )}
        </div>
        <div className={classes.item}>
          <Price value={finalCost} currency={__(currency)} />
        </div>
      </>
    )
  );
};

export default SubscriptionFoundingSource;
