import { Button } from "@benefit-systems/common-components";
import { Form, Formik } from "formik";
import { FC, useMemo } from "react";

import __ from "web/Layout/Translations";

import Field from "web/Components/Common/Form/Field/field";

import {
  getFieldSpecificProps,
  getInitialValues,
} from "web/utils/page/product/subscription/forms/prepareFormData";
import { validateFields } from "web/utils/system/formValidator/validation";

import {
  ISubscriptionFormData,
  ISubscriptionReceiverFormValues,
  SubscriptionActiveFormFieldType,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import styles from "./receiverEmailForm.scss";

interface IReceiverEmailFormProps {
  formData: ISubscriptionFormData;
  receiverEmail: Nullable<string>;
  resend: boolean;
  submitHandler: (values: ISubscriptionReceiverFormValues) => void;
  nextSendAfter?: string;
}

const fieldClasses = {
  root: styles.input,
  label: styles.label,
  labelText: styles.labelText_required,
  buttons: styles.buttons,
};

const ReceiverEmailForm: FC<IReceiverEmailFormProps> = ({
  formData,
  receiverEmail,
  resend,
  submitHandler,
  nextSendAfter,
}) => {
  const fields = [
    {
      id: "receiverEmail",
      label: __("E-mail"),
      type: SubscriptionActiveFormFieldType.EMAIL,
      value: "",
      required: true,
    },
    {
      id: "confirmEmail",
      label: __("Powtórz e-mail"),
      type: SubscriptionActiveFormFieldType.CONFIRM_EMAIL,
      value: "",
      required: true,
    },
  ];

  const [initialValues, fieldsToValidate] = useMemo(
    () => getInitialValues(fields, formData),
    [receiverEmail]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const validate = validateFields(fieldsToValidate);

  const disabledButtonByLimitingEmailSending = !!(nextSendAfter && new Date(nextSendAfter).getTime() > new Date().getTime());

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues as ISubscriptionReceiverFormValues}
      onSubmit={submitHandler}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      validate={validate}
    >
      <Form noValidate>
        {fields.map((field) => (
          <Field
            classes={fieldClasses}
            key={field.type}
            label={field.label}
            name={field.id}
            dataT1={field.type}
            {...getFieldSpecificProps(field.type)}
          />
        ))}
        <div className={styles.buttons}>
          <Button variant="tertiary" type="submit" disabled={disabledButtonByLimitingEmailSending} dataT1="submit">
            {resend ? __("Zapisz i wyślij") : __("Zapisz i zamknij")}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default ReceiverEmailForm;
