import { FC } from "react";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";

import Anchor from "web/Components/Common/Anchor";

import { Nullable } from "web/types/Utils";

import classes from "./tile.scss";

interface ITileProps {
  img: string;
  alt: string;
  href: string;
  closeAction: (index: Nullable<number>) => void;
}

const Tile: FC<ITileProps> = ({ img, alt = "", href, closeAction }) => {
  return (
    <Anchor
      to={href}
      className={classes.root}
      onClick={closeAction as () => void}
      data-t1="main_menu_tile_link"
      data-testid="main_menu_tile_link"
    >
      <LazyLoadImage
        src={img}
        alt={alt}
        className={classes.image}
        width={273}
        height={182}
      />
    </Anchor>
  );
};

export default Tile;
