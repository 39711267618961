/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useState } from "react";
import SwiperCore, { Mousewheel, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import Product from "web/Layout/ProductCard";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { PropsWithClasses } from "web/types/Common";
import type { ProductInList } from "web/types/Product";

import classify from "web/classify";

import defaultClasses from "./productCarousel.scss";

SwiperCore.use([Mousewheel, Navigation]);

interface IProductCarouselProps {
  products: ProductInList[];
  listingPosition?: number;
  listingAmount?: number;
  listingCategory?: string;
}

const ProductCarousel: FC<PropsWithClasses<IProductCarouselProps>> = ({
  products = [],
  classes = {},
  listingPosition = null,
  listingAmount = null,
  listingCategory = "",
}) => {
  const [isSwiperLooped, setSwiperLoop] = useState(false);

  return isArrayHasItems(products) ? (
    <div className={classes.carouselContainer}>
      <Swiper
        slidesPerView="auto"
        mousewheel={{ forceToAxis: true, sensitivity: 2 }}
        navigation
        watchOverflow
        centerInsufficientSlides
        loop={isSwiperLooped}
        onInit={(swiper) => {
          // @ts-ignore
          if (swiper?.size < swiper?.virtualSize) {
            setSwiperLoop(true);
          }
        }}
      >
        {products?.map((product, index) => {
          const positionIndex = index + 1;

          return (
            <SwiperSlide key={positionIndex}>
              <Product
                product={product}
                positionNumber={positionIndex}
                listingPosition={listingPosition as number}
                listingAmount={listingAmount as number}
                listingCategory={listingCategory}
                variant="promo"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : null;
};

export default classify<PropsWithClasses<IProductCarouselProps>>(
  defaultClasses
)(ProductCarousel);
