import { FC } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

interface ICityWithProvinceProps {
  className?: string;
  city: string;
  province: string[] | string;
  shouldCheckAttribute?: boolean;
}

const CityWithProvince: FC<ICityWithProvinceProps> = ({
  city,
  province,
  className = "",
  shouldCheckAttribute = true,
}) => {
  const { loading, error, data } = useDataCachedAttributes({
    ids: ["location_province"],
    skip: !shouldCheckAttribute,
  });

  switch (true) {
    case !!loading && (!data || !Object.keys(data).length): {
      return (
        <div className={className} data-t1="city_with_province">
          {city}
        </div>
      );
    }
    case !!error: {
      console.error(error);
      return (
        <div className={className} data-t1="city_with_province">
          {city}
        </div>
      );
    }
    default: {
      let provinceLabel = `, w. ${[province]}` as string;

      if (shouldCheckAttribute) {
        const currentOption =
          isArrayHasItems(data) &&
          data[0].attribute_options?.find(
            (option) => `${option.value}` === `${province}`
          );
        provinceLabel =
          currentOption && currentOption.label
            ? `, w. ${currentOption.label}`
            : "";
      }

      return (
        <div className={className} data-t1="city_with_province">
          {city}
          {provinceLabel}
        </div>
      );
    }
  }
};

export default CityWithProvince;
