import { Dispatch, FC, ReactElement, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import Modal from "web/Layout/SubscriptionModal";
import __ from "web/Layout/Translations";

import { ErrorData, IApiRequestMethods } from "web/api/apiRequestTypes";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import getErrorInfo from "web/utils/system/essentials/getErrorInfo";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";

import { SimpleFn } from "web/types/Common";
import {
  ISubscriptionActiveForm,
  ISubscriptionReceiver,
  ISubscriptionUserCurrent,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import { request } from "web/api";
import { useAppContext } from "web/context/app";
import { setNotificationError, setNotificationSuccess } from "web/features/app/appSlice";

import EditEmail from "../../EditEmail";
import subscriptionActivitiesListStyle from "../../subscriptionActivitiesList.scss";
import ActivityItem, { ActivityItemProps } from "../activityItem";
import styles from "./emailSenderItem.scss";

type BenefitUUID = string;
type OnToggleEditModalHandler = (
  benefitUUID: BenefitUUID,
  activeForm: ISubscriptionActiveForm
) => void;

interface IEmailSenderActivityItemProps extends ActivityItemProps {
  handleToggleEditModal?: OnToggleEditModalHandler;
  onStartEditingEmail?: SimpleFn;
  setData: Dispatch<React.SetStateAction<ISubscriptionUserCurrent>>;
  benefitUUID: BenefitUUID;
  receiver: ISubscriptionReceiver;
  resendLink: string;
}

const modalTitle =
  "Edytuj adres e-mail, na który chcesz ponownie wysłać formularz.";

const classes = {
  root: subscriptionActivitiesListStyle.modalRoot,
  title: subscriptionActivitiesListStyle.modalTitle,
  content: subscriptionActivitiesListStyle.modalContent,
};

const storage = new BrowserPersistence();

const EmailSenderActivityItem: FC<IEmailSenderActivityItemProps> = ({
  setData,
  benefitUUID,
  receiver,
  resendLink,
  ...restProps
}) => {

  const { form } = receiver || {};
  const { nextSendAfter } = form || {};

  // Initial state for button activity, base on nextSenfAfter date
  const buttonShouldBeActive = nextSendAfter ? new Date(nextSendAfter).getTime() < new Date().getTime() : true;

  // Preparation, at what time is it possible to send another request
  const prepNextSendAfter = nextSendAfter && new Date(nextSendAfter);
  
  const timeFromNextSendAfter = prepNextSendAfter &&  prepNextSendAfter.toLocaleTimeString('en-GB', { hour12: false });
  
  const dispatch = useDispatch();
  const [isEmailButtonActive, setIsEmailButtonActive] = useState(buttonShouldBeActive);
  const [blockedButtonText, setBlockedButtonText] =
    useState<Nullable<string>>(null);

  const { modal } = useAppContext();
  const { dispatch: mDispatch } = modal;

  const setModal = (content: ReactElement) => {
    mDispatch({ type: ModalActionTypes.ADD_MODAL, modal: content });
  };

  const closeModal = useCallback(() => {
    mDispatch({ type: ModalActionTypes.RESET });
  }, [mDispatch]);

  const onOpenEditEmailModalHandler = () => {
    setModal(
      <Modal title={__(modalTitle)} classes={classes}>
        <EditEmail
          receiver={receiver}
          benefitUUID={benefitUUID}
          closeModal={closeModal}
        />
      </Modal>
    );
  };

  const editEmailButton = (
    <button
      type="button"
      className={styles.editButton}
      onClick={onOpenEditEmailModalHandler}
    >
      {__("Edytuj adres e-mail")}
    </button>
  );

  const onResendEmailHandler = useCallback(async () => {
    const token = storage.getItem(storageNames.tokenAccess);

    try {
      const response = (await request(resendLink, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        method: IApiRequestMethods.POST,
        body: JSON.stringify({}),
        parseJSON: false,
      })) as Response;

      const responseData = await response.json();
      const { nextAvailableDate } = responseData || {};
      const { date } =  nextAvailableDate || {};

      const buttonShouldBeActive = date ?
       new Date(date).getTime() < new Date().getTime() : true;
      
      if (!buttonShouldBeActive && response.ok) {
        const timeFromNextSendAfter = new Date(date).toLocaleTimeString('en-GB', { hour12: false });
        const btnText = `${__("Ponowne wysłanie możliwe o")} ${timeFromNextSendAfter}`;

        setBlockedButtonText(btnText);
        setIsEmailButtonActive(false);
      }

      if(buttonShouldBeActive && response.ok)
      dispatch(setNotificationSuccess({ message: "Pomyślnie wysłano e-mail" }));

    } catch (errorData) {
      if ((errorData as ErrorData)?.response?.status === 425) {
        const additionalInfo = getErrorInfo(errorData as ErrorData);
        const blockadeDate = new Date(additionalInfo.nextAvailableDate.date);
        const unlockTime = new Date(
          blockadeDate.getTime()
        )
          .toLocaleString()
          .substr(12);
        const btnText = `${__("Ponowne wysłanie możliwe o")} ${unlockTime}`;
        setBlockedButtonText(btnText);
        setIsEmailButtonActive(false);

        const nextDateMessage = `${__(
          "Ponowne wysłanie e-maila będzie możliwe o"
        )} ${unlockTime}`;
        dispatch(setNotificationError({ message: nextDateMessage }));
      } else {
        dispatch(
          setNotificationError({
            message: __(
              "Nie udało się wysłać maila, spróbuj ponownie później."
            ),
          })
        );
      }

      newRelicErrorReport(
        errorData,
        "web-app/web/Components/Common/SubscriptionActivities/ActivityItem/EmailSenderItem/emailSenderItem.tsx - 79"
      );
    }
  }, [resendLink, dispatch, setNotificationError]);

  const getBtnText = (): string => {
    if (blockedButtonText) {
      return blockedButtonText;
    }
    if (isEmailButtonActive) {
      return __("Wyślij ponownie");
    }
    return __(`${__("Ponowne wysłanie możliwe o")} ${timeFromNextSendAfter}`);
  };

  const content = getBtnText();

  return (
    <ActivityItem
      {...restProps}
      additionalElement={editEmailButton}
      onClick={onResendEmailHandler}
      disabled={!isEmailButtonActive}
    >
      {content}
    </ActivityItem>
  );
};

export default EmailSenderActivityItem;
