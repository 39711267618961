import { Field as FieldFormik, useFormikContext } from "formik";
import { FC } from "react";

import __ from "web/Layout/Translations";

import getValueByStringPath from "web/utils/page/product/domesticTourism/getValueByStringPath";

const getAgeText = (age: number) => {
  switch (true) {
    case Number.isNaN(age): {
      return null;
    }
    case age === 1: {
      return `${age} ${__("rok")}`;
    }
    case [2, 3, 4].indexOf(age % 10) !== -1: {
      return `${age} ${__("lata")}`;
    }
    default: {
      return `${age} ${__("lat")}`;
    }
  }
};

export interface IFieldAgeProps {
  className?: string;
  type: string;
  name: string;
  id: string;
  key: string;
}

const FieldAge: FC<IFieldAgeProps> = ({
  className = "",
  type,
  name,
  id,
  key,
}) => {
  const { values } = useFormikContext<Record<string, string | number>>();
  const value = getValueByStringPath(values, name);
  const valueWithText = getAgeText(value as number);
  return (
    <div className={className} key={key}>
      {valueWithText}
      <FieldFormik id={id} name={name} type={type} key={key} />
    </div>
  );
};

export default FieldAge;
